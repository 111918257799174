var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { lazy, Suspense } from "react";
import get from "lodash.get";
var Select = lazy(function () { return import('react-select'); });
var VenuePriceRating = /** @class */ (function (_super) {
    __extends(VenuePriceRating, _super);
    function VenuePriceRating() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.options = [
            { value: 1, label: "Cheap" },
            { value: 2, label: "Medium" },
            { value: 3, label: "Expensive" },
            { value: 4, label: "Luxury" }
        ];
        _this.propagateChange = function (selected) {
            _this.props.form.setFieldValue(_this.props.field.name, selected.value);
        };
        _this.handleBlur = function () {
            _this.props.form.setFieldTouched(_this.props.field.name, true);
        };
        _this.getValueObject = function () {
            var value = get(_this.props.form.values, _this.props.field.name);
            if (value === null)
                return null;
            var options = _this.options.filter(function (option) {
                return option.value === value;
            });
            return (options.length) ? options[0] : null;
        };
        _this.render = function () {
            var selectedOption = _this.getValueObject();
            return (React.createElement("div", { className: "select-wrapper" },
                React.createElement(Suspense, { fallback: React.createElement("div", { className: "form__control" }) },
                    React.createElement(Select, { id: "taxonomy-" + _this.props.field.name, options: _this.options, onChange: _this.propagateChange, onBlur: _this.handleBlur, value: selectedOption, placeholder: "Select price range", className: "react-select", classNamePrefix: "react-select" }))));
        };
        return _this;
    }
    return VenuePriceRating;
}(React.PureComponent));
export default VenuePriceRating;
